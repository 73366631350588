// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blog-restaurant-barcode-qr-code-menu-js": () => import("./../../../src/pages/blog/restaurant-barcode-qr-code-menu.js" /* webpackChunkName: "component---src-pages-blog-restaurant-barcode-qr-code-menu-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-thank-you-js": () => import("./../../../src/pages/contact-thank-you.js" /* webpackChunkName: "component---src-pages-contact-thank-you-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-joined-js": () => import("./../../../src/pages/joined.js" /* webpackChunkName: "component---src-pages-joined-js" */),
  "component---src-pages-pricing-components-pricing-js": () => import("./../../../src/pages/pricing/components/Pricing.js" /* webpackChunkName: "component---src-pages-pricing-components-pricing-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-qr-menu-1-js": () => import("./../../../src/pages/qr-menu1.js" /* webpackChunkName: "component---src-pages-qr-menu-1-js" */),
  "component---src-pages-qr-menu-components-intro-js": () => import("./../../../src/pages/qr-menu/components/Intro.js" /* webpackChunkName: "component---src-pages-qr-menu-components-intro-js" */),
  "component---src-pages-qr-menu-components-optional-js": () => import("./../../../src/pages/qr-menu/components/Optional.js" /* webpackChunkName: "component---src-pages-qr-menu-components-optional-js" */),
  "component---src-pages-qr-menu-components-other-services-js": () => import("./../../../src/pages/qr-menu/components/OtherServices.js" /* webpackChunkName: "component---src-pages-qr-menu-components-other-services-js" */),
  "component---src-pages-qr-menu-components-screenshots-js": () => import("./../../../src/pages/qr-menu/components/Screenshots.js" /* webpackChunkName: "component---src-pages-qr-menu-components-screenshots-js" */),
  "component---src-pages-qr-menu-components-why-js": () => import("./../../../src/pages/qr-menu/components/Why.js" /* webpackChunkName: "component---src-pages-qr-menu-components-why-js" */),
  "component---src-pages-qr-menu-index-js": () => import("./../../../src/pages/qr-menu/index.js" /* webpackChunkName: "component---src-pages-qr-menu-index-js" */),
  "component---src-pages-qr-ordering-components-features-js": () => import("./../../../src/pages/qr-ordering/components/Features.js" /* webpackChunkName: "component---src-pages-qr-ordering-components-features-js" */),
  "component---src-pages-qr-ordering-components-foodstagram-js": () => import("./../../../src/pages/qr-ordering/components/Foodstagram.js" /* webpackChunkName: "component---src-pages-qr-ordering-components-foodstagram-js" */),
  "component---src-pages-qr-ordering-components-how-it-works-js": () => import("./../../../src/pages/qr-ordering/components/HowItWorks.js" /* webpackChunkName: "component---src-pages-qr-ordering-components-how-it-works-js" */),
  "component---src-pages-qr-ordering-components-intro-js": () => import("./../../../src/pages/qr-ordering/components/Intro.js" /* webpackChunkName: "component---src-pages-qr-ordering-components-intro-js" */),
  "component---src-pages-qr-ordering-components-loyalty-js": () => import("./../../../src/pages/qr-ordering/components/Loyalty.js" /* webpackChunkName: "component---src-pages-qr-ordering-components-loyalty-js" */),
  "component---src-pages-qr-ordering-components-outro-js": () => import("./../../../src/pages/qr-ordering/components/Outro.js" /* webpackChunkName: "component---src-pages-qr-ordering-components-outro-js" */),
  "component---src-pages-qr-ordering-components-payments-js": () => import("./../../../src/pages/qr-ordering/components/Payments.js" /* webpackChunkName: "component---src-pages-qr-ordering-components-payments-js" */),
  "component---src-pages-qr-ordering-components-why-js": () => import("./../../../src/pages/qr-ordering/components/Why.js" /* webpackChunkName: "component---src-pages-qr-ordering-components-why-js" */),
  "component---src-pages-qr-ordering-index-js": () => import("./../../../src/pages/qr-ordering/index.js" /* webpackChunkName: "component---src-pages-qr-ordering-index-js" */),
  "component---src-pages-restaurant-apps-components-features-js": () => import("./../../../src/pages/restaurant-apps/components/Features.js" /* webpackChunkName: "component---src-pages-restaurant-apps-components-features-js" */),
  "component---src-pages-restaurant-apps-components-how-it-works-js": () => import("./../../../src/pages/restaurant-apps/components/HowItWorks.js" /* webpackChunkName: "component---src-pages-restaurant-apps-components-how-it-works-js" */),
  "component---src-pages-restaurant-apps-components-intro-js": () => import("./../../../src/pages/restaurant-apps/components/Intro.js" /* webpackChunkName: "component---src-pages-restaurant-apps-components-intro-js" */),
  "component---src-pages-restaurant-apps-components-kitchen-app-section-js": () => import("./../../../src/pages/restaurant-apps/components/KitchenAppSection.js" /* webpackChunkName: "component---src-pages-restaurant-apps-components-kitchen-app-section-js" */),
  "component---src-pages-restaurant-apps-components-other-services-js": () => import("./../../../src/pages/restaurant-apps/components/OtherServices.js" /* webpackChunkName: "component---src-pages-restaurant-apps-components-other-services-js" */),
  "component---src-pages-restaurant-apps-components-waiter-app-section-js": () => import("./../../../src/pages/restaurant-apps/components/WaiterAppSection.js" /* webpackChunkName: "component---src-pages-restaurant-apps-components-waiter-app-section-js" */),
  "component---src-pages-restaurant-apps-components-why-js": () => import("./../../../src/pages/restaurant-apps/components/Why.js" /* webpackChunkName: "component---src-pages-restaurant-apps-components-why-js" */),
  "component---src-pages-restaurant-apps-index-js": () => import("./../../../src/pages/restaurant-apps/index.js" /* webpackChunkName: "component---src-pages-restaurant-apps-index-js" */),
  "component---src-pages-ru-qr-menu-js": () => import("./../../../src/pages/ru/qr-menu.js" /* webpackChunkName: "component---src-pages-ru-qr-menu-js" */),
  "component---src-pages-start-js": () => import("./../../../src/pages/start.js" /* webpackChunkName: "component---src-pages-start-js" */)
}

